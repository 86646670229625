import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import Spinner from "../../HomePage/Spinner";
import axios from "axios";
import { Helmet } from "react-helmet";
import TopHeading from "../../TopHeading";

const DrivenSystem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [drivenSystems, setDrivenSystems] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/composites"
        );
        setDrivenSystems(data.driven_systems);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>Driven Systems for Composites - Precision & Durability by McCoy</title>
        <meta name="description" Content="Explore McCoy’s driven systems for composites, designed to deliver speed, precision, and reliability in textile production." />
        <meta name="keywords" content="Driven Systems for Composites" />
      </Helmet>

      <TopHeading
        title={"Driven Systems for High-Performance Textiles"}
        name={"Driven System"}
      />

      <section className="py-5">
        <div className="container-box pb-5 pt-4">
          {drivenSystems.map((machine, i) => {
            return (
              <div
                className="row justify-content-between align-items-md-center"
                key={i}
              >
                <div className="col-md-6">
                  <h2 className="text-dark-blue h2 fw-bold">{machine.title}</h2>
                  <p className="paragraph mt-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: machine.overview,
                      }}
                    />
                  </p>
                </div>

                <div className="col-md-6 ps-md-5 px-0">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.textile_image}`}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default DrivenSystem;
