import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const TopHeading = ({ title, name }) => {
    return (
        <section className="header-section">
            <div className=" container-box">
                <div className="row">
                    <div className="col-md-10">
                        <div className="header-heading">
                            <h1>{title}</h1>
                            <p>
                                <Link to="/" aria-label="Home Page Link">
                                    Home
                                </Link>
                                <FontAwesomeIcon
                                    icon={faArrowRightLong}
                                    className="px-3"
                                ></FontAwesomeIcon>
                                {name}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopHeading