import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// css
import "../../assets/css/ContactUs.css";
import axios from "axios";
import Spinner from "../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../TopHeading";

const ContactSales = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [sales, setSales] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/sales"
        );
        setSales(data.sales);
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>Request a Quote - McCoy Textile Equipment Solutions</title>
        <meta name="description" Content="Request a personalized quote from McCoy for top-quality textile equipment and expert solutions tailored to your needs." />
        <meta name="keywords" content="Request a Quote" />
      </Helmet>
      
      <TopHeading
        title={"Request a Quote for Textile Solutions"}
        name={"Request A Quote"}
      />

      <section className="py-5 bg-light">
        <div className="container-box py-5">
          {sales.map((item, index) => {
            return (
              <div className="row justify-content-between" key={index}>
                <div className="col-md-4">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/${item.sales_bg}`}
                    className="w-100 animated-image"
                    alt=""
                  />
                </div>
                <div className="col-md-7 text-center mt-md-0 mt-5">
                  <h2 className="text-dark-blue h2 mb-5">{item.sales_text}</h2>

                  <a
                    href={`mailto:${item.sales_button_text}`}
                    target="_blank"
                    className="carousel-content-btn text-lowercase"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="pe-3"
                    ></FontAwesomeIcon>
                    {item.sales_button_text}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-5 ">
          {ctaSection.map((item, index) => {
            return (
              <div className="row align-items-center">
                <div className="col-md-8 pe-md-5">
                  <h2 className="h2 text-dark-blue">{item.salescta_title} </h2>
                  <h5 className="h5 py-2">{item.salescta_subtitle}</h5>

                  <p className="paragraph text-justify">
                    {item.salescta_overview}
                  </p>
                  <Link
                    to="/contact"
                    className="carousel-content-btn mt-2 d-inline-block"
                  >
                    {" "}
                   {item.salescta_btntext}
                  </Link>
                </div>

                <div className="col-md-4 text-end d-md-block d-none">
                  <img src={`https://mccoy-usa.com/admin/assets/front/img/${item.salescta_img}`} alt="" className="w-100" />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ContactSales;
