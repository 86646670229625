import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../../TopHeading";

const UnrollingCreels = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   {
  //     title: "MODEL 24",
  //     image: unrollingCreel2,
  //     desc: "Designed specifically with Pre-Preg in mind, the Model 24 creel delivers an industry first waist height yarn sheet for quick fiber defect detection. Also incorporating very shallow brake angles to minimize fiber damage, and thus ensuring the highest quality end product.",
  //     pdf: "https://www.mccoy-usa.com/pdf/mccoy/Model-24-Specifications.pdf",
  //   },
  //   {
  //     title: "MODEL 26",
  //     image: unrollingCreel1,
  //     desc: "Initially designed as a Composite Weaving Creel, the Model 26 has been adopted to fit many applications in the industry including Pultrusion, Coating, Pre-Preg and Chopping as well as Weaving. With a variety of tension systems available, the Model 26 is our most versatile creel option and a favorite among customers.",
  //     pdf: "https://www.mccoy-usa.com/pdf/mccoy/Model-26-Specifications.pdf",
  //   },
  // ];

  const [isLoading, setLoading] = useState(true);
  const [unrollingCreels, setUnrollingCreels] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/composites"
        );
        setUnrollingCreels(data.unrolling_creels);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/mainpage"
        );
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>Tension-Controlled Creels - McCoy Textile Solutions</title>
        <meta name="description" Content="Optimize your textile production with McCoy's tension-controlled creels. Experience advanced solutions designed for precision, reliability, and superior fiber handling." />
        <meta name="keywords" content="Tension-Controlled Creels" />
      </Helmet>
      
      <TopHeading
        title={"Reliable Tension-Controlled Creels"}
        name={"Unrolling Creels"}
      />

      <section className="py-5">
        <div className="container-box pb-4">
          <div className="mb-5">
            <h2 className="text-dark-blue h2">
              MODEL 24 & 26 UNROLLING CREELS
            </h2>
            <h5 className="fw-bold my-4">MORE THAN 50 YEARS OF EXPERTISE</h5>
            <p className="paragraph">
              With over 50 years of experience handling fiber, we utilized our
              knowledge and expertise to design and produce the next generation
              composite creels. Utilizing all locally sourced materials, both
              the Model 24 and Model 26 creels are 100% Made in the U.S.A.
              Designed to be simple, rugged, and accurate the McCoy Unrolling
              Creels are the base from which top-quality composite products are
              produced.
            </p>
          </div>
        </div>
        {unrollingCreels.map((machine, i) => {
          return i % 2 === 0 ? (
            <section className="bg-light py-4">
              <div className="container-box row align-items-center py-5">
                <div className="col-md-5 px-0">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.textile_image}`}
                    alt=""
                    className="w-100 border machine-image"
                  />
                </div>
                <div className="col-md-7 mt-md-0 mt-5 px-0">
                  <div className="ps-md-5">
                    <h3 className="text-dark-blue fw-bold">{machine.title}</h3>
                    <p className="my-4 paragraph">
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: machine.overview,
                        }}
                      />
                    </p>

                    <a
                      href={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.files}`}
                      target="_blank"
                      rel="noreferrer"
                      className="carousel-content-btn machines-btns bg-dark mt-md-0 mt-4 border-0 d-inline-block"
                    >
                      Specifications
                    </a>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="bg-white py-md-4 pt-4">
              <div className="container-box row align-items-center flex-md-row flex-column-reverse py-5">
                <div className="col-md-7 px-0">
                  <div className="pe-md-5">
                    <h3 className="text-dark-blue fw-bold">{machine.title}</h3>
                    <p className="my-4 paragraph">
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: machine.overview,
                        }}
                      />
                    </p>
                    <a
                      href={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.files}`}
                      target="_blank"
                      rel="noreferrer"
                      className="carousel-content-btn machines-btns mt-md-0 mt-4 bg-dark border-0 d-inline-block"
                    >
                      Specifications
                    </a>
                  </div>
                </div>
                <div className="col-md-5 px-0 mb-md-0 mb-5">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.textile_image}`}
                    alt=""
                    className="w-100 border"
                  />
                </div>
              </div>
            </section>
          );
        })}
      </section>

      <section className="py-5 section-2">
        <div className="container-box py-4 row align-items-center">
          {ctaSection.map((item, index) => {
            return (
              <div className="col-md-7 text-white" key={index}>
                <h2 className="h2">{item.cta_section_text}</h2>
                <p className="paragraph mt-3">{item.cta_section_overview}</p>
                <Link
                  to="/contact/request-a-quotes"
                  className="carousel-content-btn bg-gold d-inline-block mt-4"
                >
                  {" "}
                  Request a quote
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default UnrollingCreels;
