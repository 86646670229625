import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../../TopHeading";

const WarpPreparation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [wordPrepration, setWordPrepration] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/textile"
        );
        setWordPrepration(data.warp_preparation);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/mainpage"
        );
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);
  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>USA Made Warpers | High-Speed Direct Warping | Textile Warping Machines </title>
        <meta name="description" Content="Discover USA-made warpers, high-speed direct warping machines, and precision textile warping solutions by McCoy. Built for efficiency and superior performance." />
        <meta name="keywords" content="High-Speed Direct Warping, Textile Warping Machines" />
      </Helmet>

      <TopHeading
        title={"Advanced High-Speed Warping Machines"}
        name={"Warp Preparation"}
      />

      <section className="py-md-5 pt-4">
        {wordPrepration.map((machine, i) => {
          return i % 2 === 0 ? (
            <section className="bg-white py-md-4 pt-4">
              <div className="container-box row align-items-center flex-md-row flex-column-reverse py-5">
                <div className="col-md-7 px-0">
                  <div className="pe-md-5">
                    <h3 className="text-dark-blue fw-bold">{machine.title}</h3>
                    <p className="my-4 paragraph">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: machine.overview,
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="col-md-5 px-0 mb-md-0 mb-5">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.textile_image}`}
                    alt=""
                    className="w-100 border"
                  />
                </div>
              </div>
            </section>
          ) : (
            <section className="bg-light py-4">
              <div className="container-box row align-items-center py-5">
                <div className="col-md-5 px-0">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/courses/${machine.textile_image}`}
                    alt=""
                    className="w-100 border machine-image"
                  />
                </div>
                <div className="col-md-7 mt-md-0 mt-5 px-0">
                  <div className="ps-md-5">
                    <h3 className="text-dark-blue fw-bold">{machine.title}</h3>
                    <p className="my-4 paragraph">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: machine.overview,
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </section>

      <section className="py-5 section-2">
        <div className="container-box py-4 row align-items-center">
          {ctaSection.map((item, index) => {
            return (
              <div className="col-md-7 text-white" key={index}>
                <h2 className="h2">{item.cta_section_text}</h2>
                <p className="paragraph mt-3">{item.cta_section_overview}</p>
                <Link
                  to="/contact/request-a-quotes"
                  className="carousel-content-btn bg-gold d-inline-block mt-4"
                >
                  {" "}
                  Request a quote
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default WarpPreparation;
