import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

// contact icon images
import locationIcon from "../../assets/images/location.png";
import contactIcon from "../../assets/images/contact.png";
import emailIcon from "../../assets/images/email.png";
import loaderImg from "../../assets/images/loader.gif";

import axios from "axios";
import swal from "sweetalert";

// css
import "../../assets/css/ContactUs.css";
import Spinner from "../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../TopHeading";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  // state for form data
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  // state for errors messages
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!contactData.name) {
      newErrors.name = "First name is required";
    }

    if (!contactData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    if (!contactData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!contactData.message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // sending clients query
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const formData = new FormData();

        formData.append("name", contactData.name);
        formData.append("email", contactData.email);
        formData.append("phone", contactData.phone);
        formData.append("message", contactData.message);

        await axios.post("https://mccoy-usa.com/admin/api/contact", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        setLoading(false);
        swal(
          "Message Sent",
          "Your Query has been sent successfully",
          "success"
        );
        setContactData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        swal("Something Went Wrong!", "", "error");
      }
    }
  };

  // set Form Data
  const handleChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const [spinner, setSpinner] = useState(true);
  const [contactAddress, setContactAddress] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setSpinner(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/contactus"
        );
        setContactAddress(data.contact_address);
        setSpinner(false);
      } catch (err) {
        console.log(err);
        setSpinner(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {spinner && <Spinner />}
      <Helmet>
        <title>Contact McCoy - Reach Out for Expert Textile Solutions</title>
        <meta name="description" Content="Contact McCoy for inquiries, support, or more information about our innovative textile equipment and services." />
        <meta name="keywords" content="Contact McCoy" />
      </Helmet>

      <div className={`loader ${loading ? "d-flex" : "d-none"}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>


      <TopHeading
        title={"Get in Touch with McCoy"}
        name={"Contact Us"}
      />

      <section className="py-5">
        <div className="container-box pb-md-5">
          {contactAddress.map((item, index) => {
            return (
              <div className="row mb-5" key={index}>
                <div className="col-md-4 d-flex justify-content-md-center">
                  <div>
                    <img src={locationIcon} alt="" />
                  </div>
                  <div className="ms-4 ps-2 text-dark-blue">
                    <h5 className="fw-bold">Location </h5>
                    <p className="mb-0">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.contact_addresses,
                        }}
                      /></p>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-md-center mt-md-0 mt-4">
                  <div>
                    <img src={contactIcon} alt="" />
                  </div>
                  <div className="ms-4 ps-2 text-dark-blue">
                    <h5 className="fw-bold">Contact Number </h5>
                    <p className=" mb-0">Phone :  <Link to="tel:+1 (704) 289-5413" className='text-decoration-none' style={{ color: "#485f7a" }}>(704) 289-5413</Link></p>
                    <p className="mb-0">Fax &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:  <Link to="tel:+1 (704) 283-0480" className='text-decoration-none' style={{ color: "#485f7a" }}>(704) 283-0480</Link></p>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-md-center mt-md-0 mt-4">
                  <div>
                    <img src={emailIcon} alt="" />
                  </div>
                  <div className="ms-4 ps-2 text-dark-blue">
                    <h5 className="fw-bold">Email Address </h5>
                    <p className="mb-0"><Link to="mailto:info@mccoy-usa.com" className='text-decoration-none' style={{ color: "#485f7a" }}>{item.contact_mails}</Link></p>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="row justify-content-between">
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3269.0455339354876!2d-80.5329820251255!3d34.980524168148854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885437266913aac1%3A0x1a6c629d486f1a79!2sMcCoy%20Machinery%20Co.%2C%20Inc.!5e0!3m2!1sen!2sin!4v1688472732301!5m2!1sen!2sin"
                className="w-100"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="col-md-6 ps-md-5 mt-md-0 mt-5">
              <form
                action="#"
                onSubmit={handleSubmit}
                className="contact-form ps-md-4"
              >
                <h1 className="text-dark-blue mb-2 fw-bold">
                  Drop us Message for any Query
                </h1>
                <div className="row">
                  <div className="mt-4 col-12">
                    <input
                      type="text"
                      name="name"
                      value={contactData.name}
                      onChange={handleChange}
                      placeholder="Full Name"
                    />

                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>

                  <div className="mt-4 col-md-6">
                    <input
                      type="email"
                      name="email"
                      value={contactData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                    />

                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>

                  <div className="mt-4 col-md-6">
                    <input
                      type="number"
                      name="phone"
                      value={contactData.phone}
                      onChange={handleChange}
                      placeholder="Phone No"
                    />

                    {errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )}
                  </div>

                  <div className="mt-4 col-12">
                    <textarea
                      name="message"
                      value={contactData.message}
                      onChange={handleChange}
                      rows="4"
                      spellCheck={false}
                      placeholder="Drop your message"
                    ></textarea>

                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>
                  <div className="mt-4">
                    <button className="carousel-content-btn border-0 ">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
