import React, { useEffect, useState } from "react";
import "../../assets/css/About.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../TopHeading";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [about, setAbout] = useState([]);
  const [aboutSecond, setAboutSecond] = useState([]);
  const [historySection, setHistorySection] = useState([]);
  const [industrySection, setIndustrySection] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/about"
        );
        setAbout(data.about);
        setAboutSecond(data.about_second);
        setHistorySection(data.history_section);
        setIndustrySection(data.industry_section);
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);
  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>American Textile Machinery - Built for Excellence</title>
        <meta name="description" Content="Discover McCoy’s premium American textile machinery designed to enhance productivity and efficiency." />
        <meta name="keywords" content="Warp preparation equipment, American Textile Machinery" />
      </Helmet>

      <TopHeading
        title={"Superior American Textile Machinery"}
        name={"About Us"}
      />

      <section className="pb-5 pt-md-3">
        <div className="container-box py-5">
          {about.map((item, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-md-7 pe-md-5">
                  <h2 className="text-dark-blue h2 mb-3">
                    {item.aboutintro_title}
                  </h2>
                  <p className="paragraph text-justify">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.aboutintro_overview,
                      }}
                    />
                  </p>
                </div>

                <div className="col-md-5 mt-md-5 pt-md-4 pt-5">
                  <div className="position-relative">
                    <img
                      src={`https://mccoy-usa.com/admin/assets/front/img/${item.aboutintro_img}`}
                      className="about-img"
                      alt="about-image"
                    />
                    <img
                      src={`https://mccoy-usa.com/admin/assets/front/img/${item.aboutintro_img2}`}
                      className="overlay-img"
                      alt="about-overlay-image"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container-box py-md-5">
          {aboutSecond.map((item, index) => {
            return (
              <div className="row align-items-center" key={index}>
                <div className="col-md-5">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/${item.aboutsecond_img}`}
                    alt=""
                    className="w-100 america-flag"
                  />
                </div>

                <div className="col-md-7 ps-md-5 mt-md-0 mt-4">
                  <h2 className="text-dark-blue h2">
                    {item.aboutsecond_title}
                  </h2>

                  <h5 className="py-2 h5 fw-bold">
                    {item.aboutsecond_subtitle}
                  </h5>

                  <p className="paragraph mb-0 text-justify">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.aboutsecond_overview,
                      }}
                    />
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="py-5">
        {historySection.map((item, index) => {
          return (
            <div className="container-box py-md-5" key={index}>
              <div className="head-section">
                <h2 className="text-dark-blue h2 mb-4">{item.history_title}</h2>
              </div>

              <div>
                <img
                  src={`https://mccoy-usa.com/admin/assets/front/img/${item.history_img}`}
                  className="w-100"
                  alt="Company History"
                />
              </div>
            </div>
          );
        })}
      </section>

      <section className="py-5 bg-dark-blue">
        {industrySection.map((item, index) => {
          return (
            <div
              className="container-box py-md-5 text-white text-center"
              key={index}
            >
              <h2 className="h2">{item.industry_title}</h2>
              <p className="paragraph mt-4 mb-0">{item.industry_overview}</p>
            </div>
          );
        })}
      </section>

      <section className="py-5">
        <div className="container-box py-md-5 ">
          {ctaSection.map((item, index) => {
            return (
              <div className="row align-items-center" key={index}>
                <div className="col-md-8 pe-md-5">
                  <h2 className="h2 text-dark-blue">{item.cta_title}</h2>
                  <h5 className="h5 py-2">{item.cta_subtitle}</h5>

                  <p className="paragraph text-justify">
                    {item.cta_overview}
                  </p>
                  <Link
                    to="/contact"
                    className="carousel-content-btn mt-2 d-inline-block"
                  >
                    {" "}
                    {item.cta_btntext}
                  </Link>
                </div>

                <div className="col-md-4 text-end d-md-block d-none">
                  <img src={`https://mccoy-usa.com/admin/assets/front/img/${item.cta_bgimg}`} alt="" className="w-100" />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default About;
