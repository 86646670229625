import React, { useEffect, useState } from "react";

import Spinner from "./Spinner";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";

const EventDetails = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const params = useParams();
  const [isLoading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [newsDetails, setNewsDetails] = useState([]);

  useEffect(() => {
    onProductDetails();
  }, []);

  const onProductDetails = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("slug", params.slug);
    try {
      const response = await axios({
        method: "post",
        url: "https://mccoy-usa.com/admin/api/news-detail",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setNewsDetails(response.data.news);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <section className="py-5">
        <div className="container-box py-md-5">
          {newsDetails.map((item, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-md-5 pe-md-5">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/blogs/${item.main_image}`}
                    alt="eventImage"
                    className="w-100 news-img"
                  />
                </div>

                <div className="col-md-7 mt-md-0 mt-5">
                  <h1 className="text-dark-blue fw-bold">{item.title}</h1>
                  <p className="paragraph">
                    We are excited to be in Atlanta for the Techtextil North
                    America show.
                  </p>
                  <div className="d-md-flex">
                    <h5>
                      {moment(item.start_date).format("MMMM")}{" "}
                      {moment(item.start_date).format("DD")}th -{" "}
                      {moment(item.end_date).format("DD")}th
                    </h5>
                    <h5 className="ms-md-5">
                      {" "}
                      <span className="fw-light">Look for us at</span> Booth
                      2623.
                    </h5>
                  </div>

                  <div className="mt-4">
                    <h6 className="text-dark-blue">About Techtextil:</h6>
                    <p className="paragraph">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default EventDetails;
