import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// css
import "../../assets/css/ContactUs.css";
import axios from "axios";
import Spinner from "../HomePage/Spinner";
import { Helmet } from "react-helmet";
import TopHeading from "../TopHeading";

const ContactRepairService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [serviceContact, setServiceContact] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/servicecontact"
        );
        setServiceContact(data.service_contact);
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>Textile Equipment Services - Expert Maintenance & Support</title>
        <meta name="description" Content="Discover McCoy’s expert services, from installation to maintenance, ensuring optimal performance of your equipment." />
        <meta name="keywords" content="Textile Equipment Service" />
      </Helmet>
      <TopHeading
        title={"Reliable Textile Equipment Services"}
        name={"Service"}
      />

      <section className="pb-5 pt-md-3 bg-light">
        <div className="container-box py-5">
          {serviceContact.map((item, index) => {
            return (
              <div className="row justify-content-between" key={index}>
                <div className="col-md-6">
                  <h2 className="text-dark-blue h2 mb-3">
                    {item.scontact_title}
                  </h2>

                  <p className="paragraph">{item.scontact_overview}</p>
                </div>

                <div className="col-md-6 ps-md-5 mt-md-0 mt-4">
                  <h5 className="h5 text-dark-blue fw-bold">
                    {item.scontact_second_title}
                  </h5>
                  <p className="paragraph">{item.scontact_number}</p>

                  <h5 className="h5 text-dark-blue fw-bold">OR</h5>

                  <p className="paragraph">{item.scontact_email} </p>
                  <br />

                  <div className="">
                    <a
                      href={`mailto:${item.scontact_btn_text}`}
                      target="_blank"
                      className="carousel-content-btn text-lowercase"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="pe-3"
                      ></FontAwesomeIcon>
                      {item.scontact_btn_text}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="py-5">
        <div className="container-box py-md-5 ">
          {ctaSection.map((item, index) => {
            return (
              <div className="row align-items-center">
                <div className="col-md-8 pe-md-5">
                  <h2 className="h2 text-dark-blue">{item.repaircta_title} </h2>
                  <h5 className="h5 py-2">{item.repaircta_subtitle}</h5>

                  <p className="paragraph text-justify">
                    {item.repaircta_overview}
                  </p>
                  <Link
                    to="/contact"
                    className="carousel-content-btn mt-2 d-inline-block"
                  >
                    {" "}
                    {item.repaircta_btntext}
                  </Link>
                </div>

                <div className="col-md-4 text-end d-md-block d-none">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/${item.repaircta_img}`}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ContactRepairService;
